import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
export async function getMachineSoftwareVersions() {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
          query {
            getMachineSoftwareVersions {
               id
               version
            }
          }
        `,
      variables: {},
    },
  });
  return data?.data?.data?.getMachineSoftwareVersions;
}

export async function getMachineSoftwareVersionsList(where: {} = {}) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        query getMachineSoftwareVersionList(  
          $where:OtaUpdateResolver_GetMachineSoftwareVersionList_FilterInputType
        ) {
          getMachineSoftwareVersionList(where: $where) {
            id
            version
            binaryCommitPath
            configVersionPath
            updateScriptPath
            rscFilePath
            launchDateAndTime
            endDateAndTime
            bundlePath
            isLatestUpdate
            status
            createdAt
            createdById
          }
        }
      `,
      variables: { where },
    },
  });
  return data?.data?.data?.getMachineSoftwareVersionList;
}
