import { Component, createRef } from "react";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import "react-responsive-modal/styles.css";
import Select from "react-select";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { KTSVG } from "../../_metronic/helpers";
import _ from "lodash";
import clsx from "clsx";
import Moment from "moment";
import { getMachineSoftwareVersionsList } from "../models/_machine_software_version";

class ViewVersions extends Component<any, any> {
  readonly props: any;
  constructor(props: any) {
    super(props);
    this.state = {
      addLoading: false,
      filterLoading: false,
      loading: false,
      versionList: [],
      resetFrom: createRef(),
      filterFromDate: "",
      resetTo: createRef(),
      filterToDate: "",
      versionNumber: "",
      isLatestUpdate: "",
      resetIsLatestUpdate: createRef(),
      isLatestUpdateDB: [
        {
          id: "true",
          name: "Yes",
        },
        {
          id: "false",
          name: "No",
        },
        {
          id: "all",
          name: "All"
        }
      ],
    };
  }

  getLabelForLatestUpdate(status:any) {
    if (status === "true") {
      return "Yes";
    } else if (status === "false") {
      return "No";
    } else if (status === "all") {
      return "All";
    }else {
      return "All"; 
    }
  }

  async componentWillMount() { }
  async componentDidMount() {
    const rows: any = await this.getData();
    this.renderDataTable(rows);
  }
  async componentDidUpdate() { }

  getData = async (isReset = false) => {
    let filter: any = {};
    if (this.state.filterFromDate !== "" || this.state.filterToDate !== "") {
      filter["launch_date_and_time"] = {};
      filter["end_date_and_time"] = {};
    }
    if (this.state.filterFromDate !== "" && this.state.filterToDate === "") {
      filter["launch_date_and_time"]["gt"] = new Date(
        this.state.filterFromDate
      ).toISOString();
      filter["end_date_and_time"]["gt"] = new Date(
        this.state.filterFromDate
      ).toISOString();
    } else if (
      this.state.filterFromDate === "" &&
      this.state.filterToDate !== ""
    ) {
      filter["launch_date_and_time"]["lt"] = new Date(
        this.state.filterToDate
      ).toISOString();
      filter["end_date_and_time"]["lt"] = new Date(
        this.state.filterToDate
      ).toISOString();
    } else if (
      this.state.filterFromDate !== "" &&
      this.state.filterToDate !== ""
    ) {
      filter["launch_date_and_time"]["between"] = [
        new Date(this.state.filterFromDate).toISOString(),
        new Date(this.state.filterToDate).toISOString(),
      ];
      filter["end_date_and_time"]["between"] = [
        new Date(this.state.filterFromDate).toISOString(),
        new Date(this.state.filterToDate).toISOString(),
      ];
    }
    if (this.state.versionNumber !== "") {
      filter["version"] = { like: "%" + this.state.versionNumber + "%" };
    }
    if (this.state.isLatestUpdate === "true") {
      filter["is_latest_update"] = { eq: true };
    } else if (this.state.isLatestUpdate === "false") {
      filter["is_latest_update"] = { eq: false };
    } else if (this.state.isLatestUpdate === "all") {
      delete filter["is_latest_update"]
    }

    const records: any = await getMachineSoftwareVersionsList(isReset ? {} : filter);
    let counter = 0;
    const rows = [];
    for (const record of records) {
      counter++;
      let launchDate = "";
      if (record.launchDateAndTime) {
        launchDate = Moment.unix(record.launchDateAndTime / 1000)
          .local()
          .format("YYYY-MM-DD HH:mm:ss");
      }

      let endDate = "";
      if (record.endDateAndTime) {
          endDate = Moment.unix(record.endDateAndTime / 1000)
          .local()
          .format("YYYY-MM-DD HH:mm:ss");
      }
      const softwareInfo = `
        <div class="overflow-auto">
          <span class="column-width"><strong>Version Id : </strong>${record.id}</span>
          <span class="column-width"><strong>Software Version : </strong> ${record.version}</span>
          ${record.binaryCommitPath
            ? `<span class="column-width"><strong>Binary File : </strong>${record.binaryCommitPath}</span>`
            : ""}
          ${record.configVersionPath && record.configVersionPath.length > 0 && record.configVersionPath !== ""
            ? `<span class="column-width"><strong>Config Version : </strong>${record.configVersionPath}</span>`
            : ""}
          ${record.updateScriptPath.length > 0
            ? `<span class="column-width"><strong>Update Script : </strong>${record.updateScriptPath}</span>`
            : ""}
          ${record.rscFilePath.length > 0
            ? `<span class="column-width"><strong>RSC File : </strong>${record.rscFilePath}</span>`
            : ""}
        </div>
      `;
      const tempRecord = {
        id: counter,
        recordId: record.id,
        createdAt: Moment.utc(record.createdAt)
          .local()
          .format("YYYY-MM-DD HH:mm:ss"),
        launchDate: launchDate,
        endDate: endDate,
        softwareInfo: softwareInfo,
        versionNo: record.version,
        createdBy: record.createdBy?.firstName ?? "",
        status: record.status,
        isLatestUpdate: record?.isLatestUpdate ? 'Yes' : 'No',
        record: record,
      };
      rows.push(tempRecord);
    }
    this.setState({
      versionList: rows,
    });
    return rows;
  };

  renderDataTable = (rows: any) => {
    $(document).ready(function () {
      $("#updateTable").DataTable().destroy();
      $("#updateTable").DataTable({
        data: rows,
        columns: [
          {
            name: "createdAt",
            render: function (data, type, row) {
              return row.createdAt;
            },
          },
          {
            name: "softwareInfo",
            render: function (data, type, row) {
              return row.softwareInfo;
            },
          },
          {
            name: "launchDate",
            render: function (data, type, row) {
              return row.launchDate;
            },
          },
          {
            name: "endDate",
            render: function (data, type, row) {
              return row.endDate;
            },
          },
          {
            name: "isLatestUpdate",
            render: function (data, type, row) {
              return row.isLatestUpdate;
            },
          },
          {
            name: "status",
            render: function (data, type, row) {
              return _.capitalize(_.lowerCase(row.status));
            },
          },
        ],
        pagingType: "full_numbers",
        pageLength: 20,
        processing: true,
        dom: "Bfrtip",
        destroy: true,
        order: [[1, "desc"]],
        lengthMenu: [
          [10, 20, 30, 50, -1],
          [10, 20, 30, 50, "All"],
        ],
        columnDefs: [
          {
            targets: 0,
            render: function (data, type, row, meta) {
              return type === "export" ? meta.row + 1 : data;
            },
          },
        ],
      });
    });
  };

  searchData = async () => {
    this.setState({
      addLoading: true,
    });
    const rows = await this.getData();
    this.renderDataTable(rows);
    this.setState({
      addLoading: false,
    });
    return false;
  };

  removeSearch = async () => {
    this.setState({
      filterLoading: true,
    });

    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.resetFrom.current.state.inputValue = "";
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.resetTo.current.state.inputValue = "";
    this.state.resetIsLatestUpdate.current.setValue("All");
    this.setState({
      filterFromDate: "",
      filterToDate: "",
      versionNumber: "",
      isLatestUpdate: null,
    });

    const rows = await this.getData(true);
    this.renderDataTable(rows);
    this.setState({
      filterLoading: false,
    });
    return false;
  };

  render() {
    return (
      <>
        <div id="kt_app_toolbar" className="app-toolbar py-2 pt-4">
          <div
            id="kt_app_toolbar_container"
            className="app-container container-xxl d-block d-lg-flex d-md-flex d-xl-flex flex-stack px-3"
            style={{ maxWidth: "100%" }}
          >
            <div className="page-title d-flex flex-column justify-content-center flex-wrap">
              <h1 className="page-heading d-flex text-dark fw-bold fs-1 flex-column justify-content-center my-0">
                View Versions
              </h1>
            </div>
            <div className="d-flex align-items-center gap-2 gap-lg-3">
              <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <li className="breadcrumb-item text-muted">
                  <a href="/" className="text-muted text-hover-primary fs-5">
                    Home
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <span className="bullet bg-gray-400 w-5px h-2px" />
                </li>
                <li className="breadcrumb-item text-dark fs-5">View Versions</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="card mt-9">
          <div className="card-body w-100 p-lg-7 p-md-7 p-5">
            <div className="row align-items-center justify-content-between">
              <div className="col-xl-2 col-lg-3 col-md-4 col-12">
                <h3 className="btn btn-bg-light fw-bold m-0 p-0 px-5 py-3 align-items-center card-title text-center">
                  <KTSVG
                    path="/media/icons/duotune/general/gen031.svg"
                    className="svg-icon-4 me-1"
                  />
                  Filter
                </h3>
              </div>
            </div>
            <div className="row mt-6">
              <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 ps-xl-5 mb-3">
                <label className="form-label fs-4 fw-bold">From Date</label>
                <Datetime
                  ref={this.state.resetFrom}
                  closeOnSelect
                  dateFormat="YYYY-MM-DD"
                  timeFormat="HH:mm"
                  value={this.state.filterFromDate}
                  onChange={(e: any) => {
                    const dt = e["_d"];
                    this.setState({
                      filterFromDate: dt,
                    });
                  }}
                />
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                <label className="form-label fs-4 fw-bold">To Date</label>
                <Datetime
                  ref={this.state.resetTo}
                  closeOnSelect
                  dateFormat="YYYY-MM-DD"
                  timeFormat="HH:mm"
                  value={this.state.filterToDate}
                  onChange={(e: any) => {
                    const dt = e["_d"];
                    this.setState({
                      filterToDate: dt,
                    });
                  }}
                />
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                <label className="form-label fs-4 fw-bold">Version Number</label>
                <input
                  className={clsx("form-control")}
                  name="version_number"
                  id="version_number"
                  value={this.state.versionNumber}
                  onChange={(e: any) => {
                    this.setState({
                      versionNumber: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                <label className="form-label fw-bold fs-4">Is Latest Update?</label>
                <Select
                  ref={this.state.resetIsLatestUpdate}
                  className="react-select"
                  classNamePrefix="my-react-select"
                  name="isLatestUpdate"
                  defaultValue={{
                    label: this.getLabelForLatestUpdate(this.state.isLatestUpdate), 
                    value: this.state.isLatestUpdate 
                  }}
                  placeholder=""
                  onChange={(event: any) => {
                    this.setState({
                      isLatestUpdate: event.value,
                    });
                  }}
                  options={Array.from(this.state.isLatestUpdateDB).map((value: any) => {
                    return {
                      value: value.id,
                      label: value.name,
                    };
                  })}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                <button
                  type="submit"
                  onClick={this.searchData}
                  className="btn btn-primary me-4 fs-4 p-0 w-100 px-6 py-3"
                >
                  {this.state.addLoading && (
                    <span
                      className="indicator-progress"
                      style={{ display: "block" }}
                    >
                      Please Wait..
                      <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                    </span>
                  )}
                  {!this.state.addLoading && (
                    <span className="indicator-label">ADD FILTER</span>
                  )}
                </button>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                <button
                  type="submit"
                  onClick={this.removeSearch}
                  className="btn btn-primary me-4 fs-4 p-0 w-100 px-6 py-3"
                >
                  {this.state.filterLoading && (
                    <span
                      className="indicator-progress"
                      style={{ display: "block" }}
                    >
                      Please Wait..
                      <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                    </span>
                  )}
                  {!this.state.filterLoading && (
                    <span className="indicator-label">RESET FILTER</span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="card mt-9">
          <div className="card-body w-100 p-lg-7 p-md-7 p-5">
            <div className="row">
              <div className="col-12">
                <div className="table-responsive p-0 pb-2 fs-6" id="data-table">
                  <table
                    id="updateTable"
                    className="responsive-table table display align-items-center justify-content-center mb-0 w-100"
                  >
                    <thead>
                      <tr>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Created At
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Software Info
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Launch date time
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          End date time
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Is Latest Update?
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Status
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ViewVersions;
